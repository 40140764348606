<template>
  <div class="download">
    <div class="download_logo">
      <img src="../../static/image/img94.png" width="120px" />
      <div class="download_tips">
        <img src="../../static/image/img93.png" width="317px" />
      </div>
      <div class="download_title">
        <div class="download_title_left">
          <img src="../../static/image/img95.png" />
        </div>
        <div class="download_title_center">
          中峪数交以“发展产业电商、服务实体经济”为使命，积极响应国家乡村振兴、数字中国建设、数商兴农号召
          以“产业基地+在线贸易+科技应用+数字拍卖服务”为功能定位，构建产业链生态闭环。
        </div>
        <div class="download_title_right">
          <img src="../../static/image/img96.png" />
        </div>
      </div>
      <div class="download_bottom">
        <img src="../../static/image/img98.png" width="850px" />
        <div class="download_bottom_code">
          <img class="download_bottom_qdcode" src="../../static/image/img90.png" width="200px" />
          <img class="download_bottom_btn" src="../../static/image/img91.png" width="150px" />
        </div>
        <div class="download_bottom_code" style="margin-left: 40px;">
          <img class="download_bottom_qdcode" src="../../static/image/qrcode.png" width="200px" />
          <img class="download_bottom_btn" src="../../static/image/img97.png" width="150px" />
        </div>
      </div>
    </div>
    <div class="download_bottom_img">
      <img class="download_bottom_qdcode" src="../../static/image/img92.png" width="100%" />
    </div>
  </div>
</template>
<script>
import { getAccessToken, getSignature } from '@/api/index'
export default {
  name: "download",
  data() {
    return {
      showCode: false
    };
  },
  computed: {},
  mounted() {
    window.scroll(0, 0);
    this.getWXInfo() 
  },
  methods: {
    getWXInfo() {
      getSignature({ pageUrl: window.location.href }).then((res) => {
        const timestamp = res.data.timestamp
        const nonceStr = res.data.noncestr
        const signature = res.data.signature
        wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: 'wxad8dd02ae97d307f', // 必填，公众号的唯一标识
          timestamp: timestamp, // 必填，生成签名的时间戳
          nonceStr: nonceStr, // 必填，生成签名的随机串
          signature: signature,// 必填，签名
          jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData'] // 必填，需要使用的JS接口列表
        });
        wx.ready(function () {   //需在用户可能点击分享按钮前就先调用
          const obj = {
            title: '下载APP', // 分享标题
            desc: '中峪数交APP下载', // 分享描述
            link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: 'https://www.zoneyu.net/img/img98.52bc7b4a.png', // 分享图标
          }
          wx.updateAppMessageShareData(obj)
          wx.updateTimelineShareData(obj)
        });
      })
    },
  },
};
</script>
<style scoped lang="scss">
.download {
  min-width: 1240px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  background-image: url(../../static/image/img99.png);
  background-repeat: no-repeat, ;
  background-size: cover;
  position: relative;
  height: 1046px;

  .download_logo {
    padding-top: 40px;
    position: relative;

    .download_tips {
      font-size: 24px;
      color: #FFFFFF;
      line-height: 36px;
    }

    .download_title {
      width: 1240px;
      margin: 25px auto 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;

      .download_title_center {
        width: 680px;
        margin: 0 30px;
        font-size: 14px;
        color: #999999;
        ;
        line-height: 30px;
      }

      .download_title_left img,
      .download_title_right img {
        width: 250px;
        object-fit: contain;
        height: 15px;
      }
    }

    .download_bottom {
      display: flex;
      width: 1240px;
      margin: 10px auto 0 auto;
      align-items: center;
      justify-content: space-evenly;

      .download_bottom_code {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        flex-direction: column;
      }
    }

    .download_bottom_qdcode {
      border-radius: 20px;
    }

    .download_bottom_btn {
      margin-top: 30px;
    }
  }

  .download_bottom_img {
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    img{
      display: block;
    }
  }
}
</style>
